.client-homepage {
    background-color: var(--page-background);
    padding: 3em;
}

.table-homepage {
    background-color: var(--page-background);
    padding-left: 10px;
    padding-right: 10px;
}

hr.solid {
    border-top: 3px solid var(--form-background);
}

.name-margin {
    margin-right: 10px;
}

.body {
    background-color: var(--page-background);
    border: 1px rgb(156, 156, 156) solid;
    padding: 12px;
    border-radius: 3px;
}

.content {
    margin-left: auto;
    margin-right: auto;
    /* min-width: 650px;
    max-width: 1200px; */
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

span.navbar-text {
    color: var(--nav-link);
}

a.nav-link {
    color: var(--nav-link);
}

a.nav-link:hover {
    color: var(--nav-link-hover);
}

div.dropdown-menu.show {
    background-color: var(--nav-drop);
}

a.dropdown-item {
    color: var(--nav-link);
    background-color: var(--nav-drop);
}

a.dropdown-item:hover {
    color: var(--nav-link-hover);
    background-color: var(--nav-drop-hover);
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: var(--nav-link-hover);
}

.background {
    background-color: var(--page-background);
    min-height: 100vh;
}

.nav-bar-background {
    background-color: var(--page-background);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: var(--tab);
    /* color: var(--text); */
}

.modal-header {
    background-color: var(--modal-head);
    color: var(--text);
}

.modal-body {
    background-color: var(--modal-body);
    color: var(--text);
}

input#indiv-switch.form-check-input {
    background-color: var(--form-background);
}

input#indiv-switch.form-check-input:checked {
    background-color: var(--primary);
}

input.form-check-input {
    border-color: var(--text-muted);
    background-color: var(--form-background);
}

input.form-check-input:checked {
    background-color: var(--primary);
}

/* div {
    background-color: var(--form-background);
} */

.breadcrumb-text a {
    margin-left: 20px;
    color: var(--text-muted) !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
}

.breadcrumb-text a:hover {
    color: var(--teal) !important;
    text-decoration: none;
}

.contact-card {
    border: 1px rgb(156, 156, 156) solid;
    padding: 12px;
    border-radius: 3px;
}

.project-header {
    background-color: #033f57;
    color: white;
    padding: 20px;
}

.project-subheader {
    background-color: var(--form-background);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--text);
    padding: 5px 0 5px 10px;
    margin: -11px;
}

.white {
    background-color: var(--page-background);
    padding-bottom: 5px;
}

.project-section {
    display: flex;
    padding: 10px;
}

.section-label {
    display: flex;
    align-items: center;
    /* width: 200px; */
    margin-right: .5rem;
    color:var(--text-fade);
    font-size: .75rem;
    font-weight: 600;
}

.project-container {
    /* margin: 1rem; */
    margin-bottom: 1rem;
    border: 1px solid var(--form-background);;
}

.project-timeline-dot {
    margin-left: -30px;
    margin-right: 20px;
    height: 10px;
    width: 10px;
    background-color: var(--text-muted);;
    border-radius: 50%;
    display: inline-block;
}

.badge {
    padding: 5px 10px !important;
    margin-left: -10px;
}

input {
    color: var(--text) !important;
    background-color: var(--form-background);
}

.home-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-wrapper {
    /* margin: 48px 0 24px 0; */
    display: flex;
    justify-content: space-between;
}

/* @media (max-width: 675px) {
    .search-input {
        width: 100%;
    }
    .search-wrapper {
        justify-content: start;
        flex-wrap: wrap;
    }
} */

/* :root { */
    /* --bg: hsl(0, 0%, 98%);
    --bg-offset: hsl(0, 0%, 100%); */
    /* --text: hsl(200, 15%, 8%); */
    /* --gray: hsl(0, 0%, 52%);
    --border: rgba(0, 0, 0, 0.1); */
/* } */

.search-input {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNS44NTMgMTYuNTZjLTEuNjgzIDEuNTE3LTMuOTExIDIuNDQtNi4zNTMgMi40NC01LjI0MyAwLTkuNS00LjI1Ny05LjUtOS41czQuMjU3LTkuNSA5LjUtOS41IDkuNSA0LjI1NyA5LjUgOS41YzAgMi40NDItLjkyMyA0LjY3LTIuNDQgNi4zNTNsNy40NCA3LjQ0LS43MDcuNzA3LTcuNDQtNy40NHptLTYuMzUzLTE1LjU2YzQuNjkxIDAgOC41IDMuODA5IDguNSA4LjVzLTMuODA5IDguNS04LjUgOC41LTguNS0zLjgwOS04LjUtOC41IDMuODA5LTguNSA4LjUtOC41eiIvPjwvc3ZnPg==");
    background-color: var(--form-background);
    background-size: 16px 16px;
    background-position: left 10px center;
    background-repeat: no-repeat;
    padding: 1.4em 2em;
    padding-left: 2.7em;
    border: 1px solid var(--border);
    color: var(--text-muted);
    box-shadow: 0px 4px 6px var(--border);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    min-width: 25vw;
    width: 100%;
    display: flex;
    outline-color: var(--primary);
    outline-offset: 0;
}

.search-input:hover {
    box-shadow: 0px 0px 0px var(--border);
}

.breadcrumb {
    font-size:0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .05em;
}
/* 
select {
    appearance: none !important;
    outline: none;
    background-color: var(--bg-offset);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
    padding: 1em 2em;
    margin: 0;
    margin-right: 1em;
    min-width: 200px;
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    width: 100%;
    color: var(--gray);
}
.select {
    min-width: 15ch;
    max-width: 30ch;
    cursor: pointer;
    background-color: transparent;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    box-shadow: 0px 4px 6px var(--border);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.select:hover {
    box-shadow: 0px 0px 0px var(--border);
}

.select::after {
    content: "";
    display: block;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--text);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
    margin-right: 1em;
}

select,
.select:after {
    grid-area: select;
}
select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--text);
    border-radius: inherit;
} */

#project-index {
    font-size: 0.75rem;
    display: flex !important;
    justify-content: center;
    width: 100%;
}


.pagination {
    padding-top: 25px;
    padding-bottom: 25px;
}

.right-select {
    width: 200px;
    margin-left: auto; 
    margin-right: 0;
}

.help {
    color: var(--teal);
}

.help:hover {
    color: var(--teal-hover);
}

.star {
    color: var(--violet);
}

.star:hover {
    color: var(--violet-hover);
}

.btn-sm {
    margin: 0;
    padding: 0;
}

.none {
    display: none;
}

.toggle {
    display: block;
}

.full-width-page {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.lote-navbar {
    background-color: var(--header-background) !important;
}

.violet-button {
    color: var(--violet);
    border-color: var(--violet);
}

.violet-button:hover {
    color: var(--seasalt);
    background-color: var(--violet);
    border-color: var(--violet);
}

.violet-button:active {
    color: var(--seasalt);
    background-color: var(--violet);
    border-color: var(--violet);
}

.teal-button {
    color: var(--teal);
    border-color: var(--teal);
}

.teal-button:hover {
    color: var(--seasalt);
    background-color: var(--teal);
    border-color: var(--teal);
}

.teal-button:active {
    color: var(--seasalt);
    background-color: var(--teal);
    border-color: var(--teal);
}

.cerulean-button {
    color: var(--cerulean);
    border-color: var(--cerulean);
}

.cerulean-button:hover {
    color: var(--seasalt);
    background-color: var(--cerulean);
    border-color: var(--cerulean);
}

.cerulean-button:active {
    color: var(--seasalt);
    background-color: var(--cerulean);
    border-color: var(--cerulean);
}

.cerulean-button .btn-check:active+.btn, .btn-check:checked+.btn, .btn.active, .btn.show, .btn:active {
    color: var(--seasalt);
    background-color: var(--cerulean);
    border-color: var(--cerulean);
}

.help-switch .form-check-input:checked {
    background-color: var(--teal);
    border-color: var(--teal);
}

.star-switch .form-check-input:checked {
    background-color: var(--violet);
    border-color: var(--violet);
}

.complete-switch .form-check-input:checked {
    background-color: var(--success);
    border-color: var(--success);
}

.square-button {
    width: 40px !important;
    height: 38px !important;
    display: inline-block;
}

.hidden-button-1 {
    width: 10px !important;
    display: inline-block;
}

.hidden-button-2 {
    width: 30px !important;
    display: inline-block;
}

.hidden-button-3 {
    width: 20px !important;
    display: inline-block;
}

.react-datepicker {
    background-color: var(--box-background) !important;
    color: var(--text) !important;
}

.react-datepicker__header {
    background-color: var(--box-background) !important;
}

.react-datepicker__time-box {
    background-color: var(--box-background) !important;
}

.react-datepicker__current-month {
    color: var(--text) !important;
}

.react-datepicker__day-name {
    color: var(--text) !important;
}

.react-datepicker__day {
    color: var(--text) !important;
}

.react-datepicker-time__header {
    color: var(--text) !important;
}

.subbie {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.arrow {
    visibility: hidden;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--text) !important;
}

a {
    color: var(--link-colour);
}

.cont {
    display: flex;
    flex-direction: row-reverse;
}

.inline-button-container {
    display: inline-flex !important;
}

.inline-button {
    margin-left: 8px;
    margin-top: -8px;
}

#popper[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
}

.home-pop {
    transform: translate(0px, 35px);
}

.team-pop {
    transform: translate(0px, 70px);
}

.team-tool {
    transform: translate(0px, 70px);
}

.card {
    background-color: var(--box-background);
}

.card-body {
    background-color: var(--card-box-background);
}

.taskCard {
    background-color: var(--form-background) !important;
}

.star-card {
    color: var(--violet);
    border-color: var(--violet);
}

.overdueCard {
    background-color: var(--danger-highlight) !important;
}

.highlight-card-1 {
    color: var(--highlight-1);
    border-color: var(--highlight-1);
}

.highlight-card-2 {
    color: var(--highlight-2);
    border-color: var(--highlight-2);
}

.highlight-card-3 {
    color: var(--highlight-3);
    border-color: var(--highlight-3);
}

.overdue-parent-card {
    color: var(--danger);
    border-color: var(--danger);
}

.popover-header {
    color: var(--text);
    background-color: var(--tab);
}

.popover-body {
    color: var(--text);
    background-color: var(--form-background);
}
