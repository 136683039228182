.status-date {
    color: var(--primary);
}

.staff-header {
    background-color: var(--orange);
    padding: .5rem;
    font-size: .75rem;
}

.flexbox {
    display: flex;
}

.flexbox-column {
    display: flex;
    flex-direction: column;
}

.flexbox-row {
    display: flex;
    flex-direction: row;
}

#quick-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 1rem; */
}

#quick-action span{
    font-size: .8rem;
    /* margin: 1rem; */
}

#quick-action Button{
    margin: .25rem;
}

.space-between {
    /* display: flex; */
    /* flex-direction: row; */
    justify-content: space-between !important;
}

.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    border-color: var(--mid-gray);
}

.dot-0 {
    background-color: var(--success);
}

.dot-1 {
    background-color: var(--mid-gray);
}

/* Badges */

.badge-section {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.badge-type {
    margin-right: 1rem;
}

/* Stage */

.stage-section {
    display: flex;
    justify-content: space-between;
}

.project-content {
    padding: 1rem;
}

Table {
    font-size: .8rem;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
}

.custom-input {
    padding: .35rem;
}

.hide {
    display: none !important;
}

.show {
    display: block;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: var(--text);
    border-style: dashed;
    background-color: var(--form-background);
    color: var(--text-muted);
    outline: none;
    transition: border .24s ease-in-out;
}

.validate-success {
    background-color: var(--success) !important;
}

.validate-error {
    background-color: var(--danger) !important;
}

.paidInfoButton {
    height: 1rem;
    padding: 0.5rem .5rem !important;
}

.custom-icon {
    display: flex !important;
}

#custom-date-picker-label {
    transform: scale(0.85) translateY(-.5rem) translateX(.15rem);
}

.custom-date-picker {
    padding-top: 1.25rem !important;
}

td {
    vertical-align: middle;
}

.icon-flex {
    font-size: 1rem;
    display: flex !important;
    justify-content: right;
    align-items: center;
    height: 38px;
    width: 38px;
}

.react-datepicker__day--highlighted-custom-3 {
    background-color: var(--mid-gray);
    border-radius: 0.3rem;
}

/* CONTACT */

.contact-row {
    display: flex;
    padding: .25rem;
}

.contact-label {
    display: flex;
    align-items: left;
    color: var(--text-muted);
    font-size: .65rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .05rem;
}

/* Information */

.quick-action-button {
    margin-right: 1rem;
}

/* Classifications */

.classification-grid {
    border: 1px solid var(--mid-gray);
    padding: 1rem;
}

.custom-input-group-left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.custom-input-group-right {
    margin-left: -1px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: .25;
}

.table {
    margin-bottom: -1px;
}

label {
    font-size: .8rem;
}

small {
    font-size: .7rem !important;
}

span.input-group-text {
    background-color: var(--box-background);
    color: var(--text);
}

input#validationAmount.form-control {
    background-color: var(--box-background);
    color: var(--text);
}

input#payment-form-fee-1.form-control.custom-input-group-right {
    background-color: var(--form-background);
    color: var(--text);
}

.invoice-tool {
    transform: translate(0px, 70px);
}
