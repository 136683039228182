body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    --primary: #0d6efd;
    --success: #198754;
    --danger: #dc3545;
    --info: #0dcaf0;
    
    --dark-orange: #e85233;
    --orange: #f36f21;
    --teal: #21a8ab;
    --teal-hover: #006b6e;
    --violet: #d400ff;
    --violet-hover: #4f005e;
    --deep-blue: #020887;
    --iris: #000cb1;
    --green: #00641E;
    --emerald: #17d14f;
    --magenta: #B80068;
    --sky-magenta: #e31d8e;
    --cerulean: #086788;
    --link-dark: #0d6efd;
    --link-non-sat: #58a6ff;
    --pink: pink;
    --rose: pink;
    /* --stark-white: #ffffff;
    --light-gray: lightgray; */

    --gold: gold;
    --silver: silver;
    --bronze: #cd7f32;

    --crimson: #4e354f;
    --barbie: #ffe6ee;

    /* --white-smoke: #f5f5f5;
    --white-smoke-fade: #f5f5f58c;
    --bright-platinum: #f0f0f0;
    --platinum: #e5e5e5;
    --timberwolf: #d0d0d0;

    --night: #111111;
    --night-fade: #1111118c;
    --eeirie-black: #222222;
    --gunmetal: #2a2d34;
    --gray: #333333; */

    --seasalt: #F8F9FA;
    --seasalt-fade: #F8F9FA8c;
    --anti-flash-white: #E9ECEF;
    --platinum: #DEE2E6;
    --french-gray: #CED4DA;
    --mid-gray: #ADB5BD;
    --slate-gray: #6C757D;
    --outer-space: #495057;
    --onyx: #343A40;
    --gunmetal: #272D33;
    --eeirie-black: #212529;
    --eeirie-black-fade: #2125298c;
    --night: #192329;
}

@media (prefers-color-scheme: light) {
    body {
        --page-background: var(--anti-flash-white);
        --login-form-background: var(--anti-flash-white);
        --login-box-background: var(--platinum);
        --login-text: var(--eeirie-black);
        --text: var(--eeirie-black);
    }
}

@media (prefers-color-scheme: dark) {
    body {
        --page-background: var(--eeirie-black);
        --login-form-background: var(--eeirie-black);
        --login-box-background: var(--onyx);
        --login-text: var(--seasalt);
        --text: var(--seasalt);
    }
}

body.light-mode {
    --page-background: var(--anti-flash-white) !important;
    --form-background: var(--anti-flash-white);
    --box-background: var(--french-gray);
    --card-box-background: var(--platinum);

    color: var(--eeirie-black);
    --text: var(--eeirie-black) !important;
    --text-muted: var(--onyx);
    --text-fade: var(--eeirie-black-fade);
    --tab: var(--french-gray);
    --link-colour: var(--link-dark);

    --table-row: var(--platinum);

    --modal-head: var(--french-gray);
    --modal-body: var(--platinum);

    --header-background: var(--french-gray);
    --nav-link: var(--eeirie-black-fade); 
    --nav-link-hover: var(--eeirie-black);
    --nav-drop: var(--platinum); 
    --nav-drop-hover: var(--french-gray);  

    --highlight-1: var(--deep-blue);
    --highlight-2: var(--green);
    --highlight-3: var(--magenta);
    --danger-highlight: var(--barbie);
}

body.dark-mode {
    --page-background: var(--eeirie-black) !important;
    --form-background: var(--eeirie-black);
    --box-background: var(--outer-space);
    --card-box-background: var(--onyx);

    color: var(--seasalt);
    --text: var(--seasalt) !important;
    --text-muted: var(--french-gray);
    --text-fade: var(--seasalt-fade);
    --tab: var(--outer-space);
    --link-colour: var(--link-non-sat);

    --table-row: var(--gunmetal);

    --modal-head: var(--onyx);
    --modal-body: var(--gunmetal);

    --header-background: var(--onyx);
    --nav-link: var(--seasalt-fade); 
    --nav-link-hover: var(--seasalt);
    --nav-drop: var(--outer-space);    
    --nav-drop-hover: var(--onyx); 

    --highlight-1: var(--iris);
    --highlight-2: var(--emerald);
    --highlight-3: var(--sky-magenta);
    --danger-highlight: var(--crimson); 
}

a {
    text-decoration: none !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.block {
    position: absolute;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}
