.form-fail {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: var(--danger);
}

input.form-control {
    background-color: var(--form-background);
    color: var(--text);
}

input.form-control:focus {
    background-color: var(--form-background);
    color: var(--text);
}